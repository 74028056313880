import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToGroup, addProductGroup, addProduct, Product, clearProducts } from '../store/app/productSlice';
import { APIProductItem, APITabItem, APITabProductItem } from '../types/types';
import { RootState } from '../store/store';

/**
 * Fetch data from the API and dispatch to store
 */
export function useFetchDataFromAPI (): { loading: boolean } {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const products = useSelector((state: RootState) => state.products.products);

  useEffect(() => {
    async function fetchData (): Promise<void> {
      setLoading(true);

      const [productGroupsResponse, productsResponse, itemsResponse] = await Promise.all([
        fetch(process.env.NEXT_PUBLIC_API + '/getTabs'),
        fetch(process.env.NEXT_PUBLIC_API + '/getTabProds'),
        fetch(process.env.NEXT_PUBLIC_API + '/getProds'),
      ]);

      if (productGroupsResponse) {
	try {
          const productGroups: Array<APITabItem> = await productGroupsResponse.json();
          addProductGroupsToStore(productGroups, dispatch);
        } catch (e) {
           console.error(e);
        }
      }

      if (productsResponse && itemsResponse) {
        const products: Array<APITabProductItem> = await productsResponse.json();
        // Individual sellable items which belong to a product
        const items: Array<APIProductItem> = await itemsResponse.json();
        addProductsToStore(products, items, dispatch);
      }

      setLoading(false);
    }

    if (products.length > 0) {
      dispatch(clearProducts());
    }

    fetchData();
  }, []);

  return { loading };
}

/**
 * Dispatch tab response to store
 *
 * @param productGroups
 * @param dispatch
 */
function addProductGroupsToStore (productGroups: Array<APITabItem>, dispatch: Dispatch) {
  productGroups.forEach((tab) => dispatch(addProductGroup({
    id: parseInt(tab.id),
    name: tab.nimi,
    products: []
  })));

  // Add search tab
  dispatch(addProductGroup({
    id: -1,
    name: 'Haku',
    products: []
  }));
}

/**
 * Iterate through each tab collection and find the corresponding product
 * from the API response to add those to the product group
 *
 * @param products
 * @param items
 * @param dispatch
 */
function addProductsToStore (products: Array<APITabProductItem>, items: Array<APIProductItem>, dispatch: Dispatch) {
  // Go through each product in a tab
  products.forEach((product) => {
    const productItem: Product = {
      id: parseInt(product.nappi_id),
      name: product.nimi,
      price: 0,
      items: [],
      vat: 0,
      bgColor: product.color
    };

    if (product.prods && product.prods.length > 0) {
      // Go through the list of products for each collection
      product.prods.forEach((productLink) => {
        const item: APIProductItem | undefined = items.find((product) => product.id === productLink.tuote_id);

        if (item) {
          productItem.items.push({
            id: parseInt(item.id),
            name: item.nimi,
            price: parseFloat(item.hinta) + parseFloat(item.alv),
            vat: parseFloat(item.alv),
            amount: parseFloat(productLink.maara),
            vatPercentage: parseInt(item.vero)
          });
        }
      });
    }

    if (productItem.items) {
      let price = 0;
      let vat = 0;

      // Sum all the prices of an item with VAT included
      productItem.items.forEach((item) => {
        price += item.amount * item.price;
        vat += item.amount * item.vat;
      });

      // Set product total price
      productItem.price = price;
      productItem.vat = vat;
    }

    dispatch(addProductToGroup({ productGroupId: parseInt(product.tabi_id), product: productItem }));
    dispatch(addProduct(productItem));
  });
}
