import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, ButtonGroup, Toolbar } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import * as Sentry from '@sentry/browser';

import Receipt from '../Receipt';

import {
  cancelPending,
  clearExtraPurchases,
  completePurchase,
  moveToLoading,
  Purchase,
  PurchaseStates,
  removeDiscount,
  sendPaymentData,
  startPurchase
} from '../../store/app/purchasesSlice';
import { RootState } from '../../store/store';
import Connection from '../../assets/js/lib/Connection';
import { Payment, PaymentMethod } from '../../types/types';
import { AppState } from '../../store/app/appSlice';
import { calculatePurchaseTotalSum, createManualPayment } from '../../assets/js/helpers';
import { getGiftCardBalance } from '../../assets/js/api';
import ConfirmDialog from '../ConfirmDialog';

async function checkGiftCardBalance(selectedPurchase: Purchase) {
  if (selectedPurchase.giftCardCode) {
    const balance = await getGiftCardBalance(selectedPurchase.giftCardCode);
    return parseFloat(balance.summa) === selectedPurchase.discount;
  }

  return true;
}

const PaymentButtons = ({ connections }: { connections: Connection[] }): React.ReactElement => {
  // Redux dispatch function via hook
  const dispatch = useDispatch();

  // Store
  const app: AppState = useSelector((state: RootState) => state.app);
  const purchases = useSelector((state: RootState) => state.purchases);
  const products = useSelector((state: RootState) => state.products.products);

  // Component state
  const [total, setTotal] = useState(0);
  const [selectedPurchase, setSelectedPurchase] = useState<Purchase>(null);
  const [isReceiptPrinting, setReceiptPrinting] = useState<boolean>(false);
  const [isConfirmCashPaymentDialogOpen, setConfirmCashPaymentDialogOpen] = useState<boolean>(false);

  const toggleConfirmPaymentDialogOpen = (open: boolean) => {
    setConfirmCashPaymentDialogOpen(open);
  };

  const handleCashPaymentConfirmation = async () => {
    await payCash();
  };

  // Component mounted
  useEffect(() => {
    setSelectedPurchase(purchases.purchases.find(purchase => purchase.id === purchases.selectedPurchase));
  }, []);

  // Calculate selected purchase total sum and vat sums once selectedPurchase is set
  useEffect(() => {
    const selectedPurchase = purchases.purchases.find(purchase => purchase.id === purchases.selectedPurchase);

    if (selectedPurchase) {
      setSelectedPurchase(selectedPurchase);
      let { sum } = calculatePurchaseTotalSum(selectedPurchase, products);
      setTotal(sum);
    }
  }, [purchases.selectedPurchase, purchases.purchases, products]);

  const startNewPayment = () => {
    dispatch(clearExtraPurchases());

    setTimeout(() => {
      dispatch(startPurchase());
    }, 2500);
  };

  const successfulPaymentCallback = (result: Payment, paymentDeviceConnection?: Connection) => {
    if (result.amount === (total * 100)) {
      // Customer paid the correct amount
      toast.success('Ostotapahtuma maksettu.', { delay: 7500 });

      // Mark purchase as completed
      dispatch(completePurchase({
        purchaseId: selectedPurchase.id,
        paymentData: {
          ...result,
          payment_device_id: (paymentDeviceConnection ? paymentDeviceConnection.terminalId : null)
        }
      }));

      dispatch(sendPaymentData());
      startNewPayment();
    } else if (result.amount > (total * 100)) {
      // Customer paid too much
      toast.success('Ostotapahtuma maksettu.', { delay: 7500 });
      // Warn about overcharge
      toast.warning('Asiakasta veloitettiin yli ostotapahtuman kokonaissumman, ' + total.toFixed(2).replace('.', ',') + ' €. Veloitettu ja maksettu ' + (result.amount / 100).toFixed(2).replace('.', ',') + ' €', { delay: 7500 });

      dispatch(completePurchase({
        purchaseId: selectedPurchase.id,
        paymentData: {
          ...result,
          payment_device_id: (paymentDeviceConnection ? paymentDeviceConnection.terminalId : null)
        }
      }));

      dispatch(sendPaymentData());
      startNewPayment();
    } else if (result.amount < (total * 100)) {
      // Customer paid too little
      toast.warning('Ostotapahtumaa ei maksettu kokonaisuudessaan.', { delay: 7500 });
      toast.warning('Veloitettu ja maksettu ' + (result.amount / 100).toFixed(2).replace('.', ',') + ' €. Ostotapahtuman kokonaissumma oli ' + total.toFixed(2).replace('.', ',') + ' €', { delay: 7500 });
    }
  };

  /**
   * Pay with card dispatches a request to the payment device to begin a purchase event
   *
   * @param terminalId
   */
  const payCard = async (terminalId: string) => {
    const isGiftCardValid = await checkGiftCardBalance(selectedPurchase);
    const connection = connections.find((connection) => connection.terminalId === terminalId);

    if (connection) {
      if (!isGiftCardValid) {
        toast.warn('Lahjakortilla ollut saldo on vanhentunut. Ole hyvä ja syötä lahjakortti uudelleen.');
        dispatch(removeDiscount());
        return false;
      }

      // Move purchase as pending
      dispatch(moveToLoading({ purchaseId: selectedPurchase.id, terminalId }));

      // Build the payment request
      const req = {
        receipt_id: parseInt(selectedPurchase.sequenceId),
        bypass_pin: false,
        no_timeout: true,
        forced_authorization: false,
        currency: 'EUR',
        stop_on_card_info: false,
        stop_on_app_info: false,
        sequence_id: parseInt(selectedPurchase.sequenceId),
        amount: Math.round(total * 100)
      };

      connection.request('Purchase', req, (result: Payment) => successfulPaymentCallback({
        ...result,
        discountPercentage: selectedPurchase.discountPercentage ?? 0,
        payment_method: PaymentMethod.CARD,
        cash_registry: app.cashRegistryId
      }, connection), function (error: { message: string, [index: string]: unknown, data: any, code: number }) {
        const { code, data } = error;

        // Todo: Capture user cancellation event
        if (code === 1 && ['USER_CANCELLED', 'POS_ABORTED'].includes(data.string_code) && data.response_code === '06') {
          dispatch(cancelPending(selectedPurchase.id));

          if (data.string_code === 'USER_CANCELLED') {
            toast.info('Käyttäjä keskeytti maksun');
          } else if (data.string_code === 'POS_ABORTED') {
            toast.info('Myyjä keskeytti maksun');
          }
        } else {
          Sentry.captureException(error);
          toast.error('Tapahtui odottamaton virhe! Ole hyvä ja yritä uudelleen.', { delay: 0 });
          dispatch(cancelPending(selectedPurchase.id));
        }
      });
    }
  };

  /**
   * Pay with cash generates a direct payment for the purchase for the exact required sum
   */
  const payCash = async () => {
    const isGiftCardValid = await checkGiftCardBalance(selectedPurchase);

    if (!isGiftCardValid) {
      toast.warn('Lahjakortilla ollut saldo on vanhentunut. Ole hyvä ja syötä lahjakortti uudelleen.');
      dispatch(removeDiscount());
      return false;
    }

    const payment = createManualPayment(app.cashRegistryId, PaymentMethod.CASH, total);
    successfulPaymentCallback({
      ...payment,
      discountPercentage: selectedPurchase.discountPercentage ?? 0,
    });
  };

  const abortTransaction = (): void => {
    const connection = connections.find((connection) => connection.terminalId === selectedPurchase.paymentPendingOnTerminalIndex);

    if (connection) {
      dispatch(moveToLoading({ purchaseId: selectedPurchase.id, terminalId: selectedPurchase.paymentPendingOnTerminalIndex }));

      connection.request('Abort', { silent: false }, () => {
        // Cancel pending purchase once Abort event has been successfully registered
        dispatch(cancelPending(selectedPurchase.id));
      });
    }

  };

  const printReceipt = (terminalId: string) => {
    setReceiptPrinting(true);

    // Just wait 5 seconds before automatically disabling the button
    setTimeout(function () {
      setReceiptPrinting(false);
    }, 5000);

    const node: HTMLElement = document.getElementById('purchase-receipt-payment-' + selectedPurchase.id);
    const connection = connections.find((connection) => connection.terminalId === terminalId);

    if (connection) {
      htmlToImage.toPng(node, {
        width: 385,
        backgroundColor: 'white',
        height: node.offsetHeight,
        quality: 1,
        pixelRatio: 1
      })
        .then(function (dataUrl) {
          const imageData = dataUrl.replace('data:image/png;base64,', '');

          connection.request('Print', {
            image: {
              format: 'png',
              data: imageData
            }
          });

          setTimeout(function () {
            setReceiptPrinting(false);
          }, 1000);

          toast.info('Kuitin tulostus aloitettu');
        });
    }
  };

  return selectedPurchase ? (
    <AppBar position="absolute" color="transparent" id={ 'payment-buttons' } elevation={ 0 }>
      { selectedPurchase.status === PurchaseStates.COMPLETED ? <Box className={ `status-bar status-success` }>Ostotapahtuma
        maksettu</Box> : <></> }
      <Toolbar variant={ 'dense' } style={ { flexWrap: 'wrap' } }>
        { selectedPurchase.status === PurchaseStates.COMPLETED ? (
          <ButtonGroup style={ { width: '100%' } }>
            { connections
              .filter((connection) => app?.usedTerminalConnections.includes(connection.terminalId))
              .map((connection: Connection) => (
                <Button key={ 'device-print-receipt-button-' + connection.terminalId } variant={ 'contained' }
                        color={ 'primary' } onClick={ () => printReceipt(connection.terminalId) }
                        disabled={ isReceiptPrinting }>
                  TULOSTA KUITTI ({ connection.name.replace('Utopia Club ', '') })
                </Button>
              )) }
            <div style={ { position: 'fixed', top: -9999, right: -9999 } }>
              <Receipt purchase={ selectedPurchase } prefix={ 'payment' } /></div>
          </ButtonGroup>
        ) : (
          <>
            <Button variant={ 'contained' } color={ 'secondary' } onClick={ () => toggleConfirmPaymentDialogOpen(true) }>
              KÄTEINEN
            </Button>w
            <ButtonGroup style={ { width: '100%' } }>
              { connections
                .filter((connection) => app?.usedTerminalConnections.includes(connection.terminalId))
                .map((connection: Connection, index: number) => (
                  <Button key={ 'device-pay-with-card-button-' + index } variant={ 'contained' } color={ 'secondary' }
                          onClick={ () => payCard(connection.terminalId) } disabled={ selectedPurchase.status !== PurchaseStates.OPEN }>
                    KORTTI ({ connection.name.replace('Utopia Club ', '') })
                  </Button>
                )) }
            </ButtonGroup>
          </>
        ) }
      </Toolbar>
      { selectedPurchase && (selectedPurchase.status === PurchaseStates.PENDING || selectedPurchase.status === PurchaseStates.LOADING)
      &&
      <Button variant={ 'contained' } color={ 'primary' } disabled={ selectedPurchase.status === PurchaseStates.LOADING } onClick={ abortTransaction }>Peruuta maksu</Button> }
      <ConfirmDialog
        title={ 'Varmista käteismaksu' }
        onSubmit={ handleCashPaymentConfirmation }
        toggleOpen={ toggleConfirmPaymentDialogOpen }
        open={ isConfirmCashPaymentDialogOpen }
      >
        Oletko varma, että haluat kuitata ostotapahtuman maksetuksi käteisellä?
      </ConfirmDialog>
    </AppBar>
  ) : <></>;
};

export default PaymentButtons;