import React, { useEffect, useState } from 'react';
import { AppBar, TextField, Toolbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Product, setSearchResults } from '../store/app/productSlice';
import { setSelectedCategory } from '../store/app/appSlice';

const SearchBar = () => {
  const [inputVal, setInputVal] = useState<string>('');
  const dispatch = useDispatch();
  const appState = useSelector((state: RootState) => state.app);
  const { searchProductGroupIndex, products: productState } = useSelector((state: RootState) => state.products);

  useEffect(() => {
    if (appState.selectedCategoryIndex !== searchProductGroupIndex) {
      setInputVal('');
    }
  }, [appState.selectedCategoryIndex]);

  /**
   * Executes a simple name search that accepts any occurrence of the
   * inputted value in a product name as a result
   *
   * @param e
   */
  const execSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.value);

    if (inputVal !== '') {
      const products = productState.filter((product: Product) => product.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
      dispatch(setSearchResults(products));
      dispatch(setSelectedCategory(searchProductGroupIndex));
    }
  };

  return (
    <AppBar position="absolute" style={ {
      top: 'auto',
      bottom: 0
    } } className={ 'search-bar' } component={ 'footer' } elevation={ 0 }>
      <Toolbar variant={ 'dense' } disableGutters={ true }>
        <TextField
          label={ '' }
          variant={ 'outlined' }
          style={ { width: '100%' } }
          onChange={ execSearch } placeholder={ 'Syötä tuotteen nimi...' }
          className={ 'no-border' }
          value={inputVal}
        />
      </Toolbar>
    </AppBar>
  );
};

export default SearchBar;