import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  AccordionSummary,
  Grid,
  Typography,
  Accordion,
  TextField, AccordionDetails
} from '@material-ui/core';
import { Print, ExpandMore } from '@material-ui/icons';
import { format } from 'date-fns';
import { Purchase, toggleReceiptDialog, updateComment } from '../store/app/purchasesSlice';
import * as htmlToImage from 'html-to-image';

import Receipt from './Receipt';
import { RootState } from '../store/store';
import Connection from '../assets/js/lib/Connection';
import { AppState } from '../store/app/appSlice';

const ReceiptsDialog = ({ connections }: { connections: Array<Connection> }) => {
  const dispatch = useDispatch();
  const purchaseStore = useSelector((state: RootState) => state.purchases);
  const appStore: AppState = useSelector((state: RootState) => state.app);

  const [completedPurchases, setCompletedPurchases] = useState([]);
  const [isReceiptPrinting, setReceiptPrinting] = useState<boolean>(false);

  useEffect(() => {
    if (purchaseStore.purchases) {
      const completed = purchaseStore.purchases.filter(purchase => purchase.status === 'completed');

      if (completed) {
        setCompletedPurchases(completed);
      }
    }
  }, [purchaseStore.purchases]);

  const closeDialog = () => {
    dispatch(toggleReceiptDialog(false));
  };

  const printReceipt = (purchase: Purchase, terminalId: string) => {
    setReceiptPrinting(true);

    // Just wait 5 seconds before automatically disabling the button
    setTimeout(function() {
      setReceiptPrinting(false);
    }, 5000);

    const node = document.getElementById('purchase-receipt-dialog-' + purchase.id);
    const connection = connections.find((connection) => connection.terminalId === terminalId);

    if (connection) {
      htmlToImage.toPng(node, {
        width: 385,
        backgroundColor: 'white',
        height: node.offsetHeight,
        quality: 1,
        pixelRatio: 1
      })
        .then(function (dataUrl) {
          const imageData = dataUrl.replace('data:image/png;base64,', '');

          connection.request('Print', {
            image: {
              format: 'png',
              data: imageData
            }
          });

          setTimeout(function() {
            setReceiptPrinting(false);
          }, 1000);

          toast.info('Kuitin tulostus aloitettu');
        });
    }
  };

  const updateCommentToPurchase = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, purchaseId: string) => {
    dispatch(updateComment({ purchaseId, comment: event.target.value }));
  };

  return (
    <Dialog
      id="customer-dialog"
      onClose={ closeDialog }
      aria-labelledby="dialog-title"
      open={ purchaseStore.isReceiptDialogOpen }
      maxWidth="md"
      fullWidth={ true }
    >
      <DialogTitle>
        Kuitit
      </DialogTitle>
      <DialogContent style={ { paddingBottom: '1rem' } }>
        { completedPurchases.length > 0 ? (
          <>
            <Grid container style={ { marginBottom: '1rem' } }>
              <Grid item xs={ 6 }>
                Ostotapahtuma
              </Grid>
              <Grid item xs={ 6 }>
                Päivitetty
              </Grid>
            </Grid>
            { completedPurchases.reverse().map((purchase: Purchase) => (
              <Accordion className={ 'completed-purchase' } key={ purchase.id }>
                <AccordionSummary
                  expandIcon={ <ExpandMore /> }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                > <Grid container>
                  <Grid item xs={ 6 }>
                    { purchase.title }
                  </Grid>
                  <Grid item xs={ 6 }>
                    { format(new Date(purchase.updated), 'dd.MM.yyyy HH:mm:ss') }
                  </Grid>
                </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={ 12 }>
                      <TextField onChange={ (e) => updateCommentToPurchase(e, purchase.id) } label="Kommentti" fullWidth={ true } />
                    </Grid>
                    <Grid item xs={ 12 }>
                      <div style={ { width: '385px', border: '1px solid #ddd', margin: '1rem 0' } }>
                        <Receipt purchase={ purchase } prefix={ 'dialog' } />
                      </div>
                      { connections
                        .filter((connection) => appStore?.usedTerminalConnections.includes(connection.terminalId))
                        .map((connection: Connection) => (
                        <Button key={ 'payment-device-print-receipt-button-' + connection.terminalId } disabled={isReceiptPrinting}
                                onClick={ () => printReceipt(purchase, connection.terminalId) }><Print /> Tulosta kuitti ({ connection.name.replace('Utopia Club ', '') })</Button>
                      )) }
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )) }
          </>
        ) : <Typography align={ 'center' }>Ei suoritettuja ostoksia</Typography> }
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={ closeDialog } style={ { marginRight: '1rem' } }>
          Sulje
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReceiptsDialog;