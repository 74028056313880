// <![CDATA[
// https://www.w3.org/TR/websockets/

type WebSocketPostArgs = {
  cloudpos: boolean;
  user: string;
  password: string;
  terminalId: string;
  host?: string;
  port?: string;
  stateChanged?: () => void;
  methods: Record<string, (MethodCallback) => void>;
}

type WebSocketPostTypes = {
  methods: Record<string, (MethodCallback) => void>;
  pending: Record<string, { timestamp: number, completeSuccess: (r: unknown) => void, completeError: (error: unknown) => void }>;
  cbStateChanged: (status: number, desc: string) => void;
}

export interface WebSocketPosInterface extends WebSocketPostTypes {
  pollStatus(): void;

  close(reason: string): void;

  sendMessage(message: Record<string, unknown>): void;

  sendResultMaybe(id: string | number, result: Record<string, unknown>): void;

  processResult(doc: { id: number | string, result: string }): void;

  processError(doc: { id: number | string, result: string, error: unknown }): void;

  sendRequest(method: string, params: Record<string, unknown>, completeSuccess: () => void, completeError: () => void): void;

  sendNotify(method: string, params: Record<string, unknown>): void;

  processMethod(doc: { method: string, id: number | string }): void;
}

const readyStateDescriptions: Record<number, string> = {
  0: 'CONNECTING: The connection is not yet open.',
  1: 'OPEN: The connection is open and ready to communicate.',
  2: 'CLOSING: The connection is in the process of closing.',
  3: 'CLOSED: The connection is closed or couldn\'t be opened.'
};

class WebSocketPos implements WebSocketPosInterface {
  private readonly connectUri;
  private readonly websock: WebSocket;

  private idCounter = 1;
  private connectedTime: null | number = null;
  public lastPolledStatus: null | number = null;

  public methods: Record<string, (MethodCallback) => void>;
  public pending: Record<string, { timestamp: number, completeSuccess: (r: unknown) => void, completeError: (error: unknown) => void }> = {};
  public cbStateChanged: (status: number, desc: string) => void;

  constructor(args: WebSocketPostArgs) {
    if (args.cloudpos) {
      this.connectUri = 'wss://' + args.user + ':' + args.password + '@api.poplatek.com/api/v2/terminal/' + args.terminalId + '/jsonpos';
    } else {
      this.connectUri = 'ws://' + args.host + ':' + args.port + '/jsonpos';
    }

    this.websock = new WebSocket(this.connectUri, ['jsonrpc2.0']);
    this.methods = args.methods;

    if (args.stateChanged) {
      this.cbStateChanged = args.stateChanged;
    }

    this.addEventListenersToWS();

    this.beginPolling();
  }

  beginPolling(): void {
    setInterval(() => {
      this.pollStatus();
    }, 1000);

    this.pollStatus();
  }

  private addEventListenersToWS(): void {
    // Open connection
    this.websock.addEventListener('open', (event: unknown) => {
      console.log('websocket open:', event);
      this.connectedTime = Date.now();
      this.pollStatus();
    });

    // Connection closes
    this.websock.addEventListener('close', (event: CloseEvent) => {
      console.log('websocket close:', event.code, event.reason);
      this.pollStatus();
    });

    // Error occurs
    this.websock.addEventListener('error', (event: unknown) => {
      console.log('websocket error:', event);
      this.pollStatus();
    });

    // Message received
    this.websock.addEventListener('message', (event: { data: unknown }) => {
        if (typeof event.data !== 'string') {
          console.log('websocket message: non-string data received, ignoring');
          return;
        }

        try {
          const doc = JSON.parse(event.data);

          console.log('POS\u003c-PT:', JSON.stringify(doc));

          if (doc.jsonrpc !== '2.0') {
            throw new Error('message missing jsonrpc == "2.0"');
          }

          if (typeof doc.method === 'string') {
            this.processMethod(doc);
          } else if (typeof doc.result !== 'undefined') {
            if (typeof doc.id === 'undefined') {
              throw new Error('result missing id');
            }

            this.processResult(doc);
          } else if (typeof doc.error !== 'undefined') {
            if (typeof doc.id === 'undefined') {
              throw new Error('error missing id');
            }

            this.processError(doc);
          } else {
            throw new Error('message missing method, result, or error');
          }
        } catch (e) {
          console.log('websocket message: failed to handle:', e);
          console.log(e.stack || e);

          const msg = 'jsonrpc handling failed: ' + e;

          this.sendMessage({ jsonrpc: '2.0', method: '_CloseReason', params: { message: msg } });
          this.websock.close(1000, msg);
        }
      }
    );
  }

  pollStatus(): void {
    const status = this.websock.readyState;

    if (status === this.lastPolledStatus) {
      return;
    }

    console.log('WebSocket status change:', this.lastPolledStatus, 'to', status);

    this.lastPolledStatus = status;
    this.cbStateChanged(status, readyStateDescriptions[status] || ('status ' + status));
  }

  close(reason: string): void {
    this.websock.close(1000, reason);
  }

  sendMessage(message: Record<string, unknown>): void {
    const data = JSON.stringify(message);
    console.log('POS-\u003ePT:', data);
    this.websock.send(data);
  }

  sendResultMaybe(id: string | number, result: Record<string, unknown>): void {
    if (typeof id === 'undefined') {
      return;
    }

    this.sendMessage({ jsonrpc: '2.0', id: id, result: result });
  }

  sendErrorMaybe(id: string | number, error: unknown): void {
    if (typeof id === 'undefined') {
      return;
    }

    this.sendMessage({ jsonrpc: '2.0', id: id, error: error });
  }

  processMethod(doc: { method: string, id: number | string }): void {
    const method = doc.method;
    const id = doc.id;

    if (method === '_Keepalive') {
      this.sendResultMaybe(id, {});
    } else if (method === '_Info') {
      this.sendResultMaybe(id, {});
    } else if (method === '_Error') {
      this.sendResultMaybe(id, {});
    } else if (method === '_CloseReason') {
      this.sendResultMaybe(id, {});
    } else {
      const fn: (
        doc: { method: string, id: number | string },
        success: (res) => void,
        error?: (err) => void
      ) => void = this.methods[method];

      let handled = false;

      if (typeof fn === 'function') {
        try {
          fn(doc, (res: Record<string, unknown>) => {
            if (handled) {
              console.log('completion called more than once, ignoring');
              return;
            }

            handled = true;
            this.sendResultMaybe(id, res);
          }, (err: unknown) => {
            if (handled) {
              console.log('completion called more than once, ignoring');
              return;
            }

            handled = true;

            this.sendErrorMaybe(id, {
              code: 1,
              message: String(err),
              data: {
                string_code: 'UNKNOWN',
                details: String(err)
              }
            });
          });
        } catch (e) {
          handled = true;

          this.sendErrorMaybe(id, {
            code: 1,
            message: String(e),
            data: {
              string_code: 'UNKNOWN',
              details: String(e.stack || e)
            }
          });
        }
      } else {
        this.sendErrorMaybe(id, {
          code: -32601,
          message: 'unhandled incoming method: ' + method,
          data: {
            string_code: 'JSONRPC_METHOD_NOT_FOUND',
            details: ''
          }
        });
      }
    }
  }

  processResult(doc: { id: number | string, result: string }): void {
    const t = this.pending[doc.id];

    delete this.pending[doc.id];

    if (typeof t === 'undefined') {
      this.sendMessage({
        jsonrpc: '2.0', method: '_Error', params: {
          message: 'result for id ' + doc.id + ' with no pending state'
        }
      });
    } else {
      t.completeSuccess(doc.result);
    }
  }

  processError(doc: { id: number | string, result: string, error: unknown }): void {
    const t = this.pending[doc.id];

    delete this.pending[doc.id];

    if (typeof t === 'undefined') {
      this.sendMessage({
        jsonrpc: '2.0', method: '_Error', params: {
          message: 'error for id ' + doc.id + ' with no pending state'
        }
      });
    } else {
      t.completeError(doc.error);
    }
  }

  sendRequest(method: string, params: Record<string, unknown>, completeSuccess: (r: unknown) => void, completeError: (e: unknown) => void): void {
    const id = 'pos-' + (this.idCounter++);
    this.sendMessage({ jsonrpc: '2.0', id: id, method: method, params: params });
    this.pending[id] = { timestamp: Date.now(), completeSuccess: completeSuccess, completeError: completeError };
  }

  sendNotify(method: string, params: Record<string, unknown>): void {
    this.sendMessage({ jsonrpc: '2.0', method: method, params: params });
  }
}

export default WebSocketPos;