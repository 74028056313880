import React, { ReactElement, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
  TextField
} from '@material-ui/core';

import { toggleAmountSelectorDialog } from '../store/app/productSlice';
import { RootState } from '../store/store';
import { addProductToOpenPurchase, Purchase, PurchaseStates } from '../store/app/purchasesSlice';

const AddProductToPurchaseWithAmount: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const purchasesState = useSelector((state: RootState) => state.purchases);
  const open = useSelector((state: RootState) => state.products.isAmountSelectorOpen);
  const productId = useSelector((state: RootState) => state.products.amountSelectorProductId);
  const [count, setCount] = useState<string>('1');
  const [openPurchase, setOpenPurchase] = useState<Purchase>(null);

  useEffect(() => {
    const purchaseIndex = purchasesState.purchases.findIndex(purchase => {
      return purchase.id === purchasesState.selectedPurchase;
    });

    if (purchaseIndex > -1) {
      setOpenPurchase(purchasesState.purchases[purchaseIndex]);
    }

  }, [purchasesState.purchases, purchasesState.selectedPurchase]);


  const save = (): void => {
    if (openPurchase.status !== PurchaseStates.OPEN) {
      toast.warn('Et voi lisätä tuotteita päätettyyn ostotapahtumaan', {
        toastId: 'unable-to-add-product-to-purchase'
      });
    } else {
      dispatch(addProductToOpenPurchase({ id: productId, count: parseFloat(count) }));
      dispatch(toggleAmountSelectorDialog({ open: false }));// Dispatch to store
      setCount('1');
    }
  };

  const handleClose = (): void => {
    dispatch(toggleAmountSelectorDialog({ open: false }));// Dispatch to store
  };

  const setValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCount(e.target.value);
  };

  const handleIncrement = (): void => {
    setCount((parseFloat(count) + 1).toString());
  };

  const handleDecrement = (): void => {
    setCount(count => (parseFloat(count) - 1).toString());
  };

  const handleIncrementQuarter = () => {
    setCount((count) => (parseFloat(count) + 0.25).toString());
  };

  const handleDecrementQuarter = () => {
    setCount(count => (parseFloat(count) - 0.25).toString());
  };

  return (
    <Dialog onClose={ handleClose } aria-labelledby="simple-dialog-title" open={ open }>
      <DialogTitle>Lisää tuote ostotapahtumaan</DialogTitle>
      <DialogContent style={ { paddingBottom: '1rem' } }>
        <FormGroup>
          <FormLabel>Määrä</FormLabel>
          <ButtonGroup style={ { marginTop: '0.5rem' } } className="edit-row-count">
            <Button onClick={ handleDecrement }>-</Button>
            <Button onClick={ handleDecrementQuarter }>-1/4</Button>
            <TextField style={ { color: '#101010', flex: 1, textAlign: 'center' } }
                       inputProps={ { style: { textAlign: 'center' } } } // the change is here
                       value={ count } onChange={ setValue } type={ 'number' } />
            <Button onClick={ handleIncrementQuarter }>1/4+</Button>
            <Button onClick={ handleIncrement }>+</Button>
          </ButtonGroup>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={ handleClose } style={ { marginRight: '1rem' } }>
          Peruuta
        </Button>
        <Button variant="contained" color="primary" onClick={ save }>
          LISÄÄ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProductToPurchaseWithAmount;