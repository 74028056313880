import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  ButtonGroup,
  TextField,
  Grid,
  FormGroup
} from '@material-ui/core';
import { toggleRowEditor, deletePurchaseRow, updateRowCount } from '../store/app/purchasesSlice';
import { RootState } from '../store/store';

const EditPurchaseRowDialog = () => {
  const dispatch = useDispatch();
  const purchaseStore = useSelector((state: RootState) => state.purchases);

  const [rowData, setRowData] = useState(null);
  // Store as string for now
  const [count, setCount] = useState<string>('0');

  useEffect(() => {
    if (purchaseStore.editedPurchaseRow > -1) {
      const purchase = purchaseStore.purchases.find(purchase => purchase.id === purchaseStore.selectedPurchase);

      if (purchase) {
        const row = purchase.rows.find((row, index) => index === parseInt(purchaseStore.editedPurchaseRow));

        if (row) {
          setRowData(row);
          setCount(row.count);
        }
      }
    }
  }, [purchaseStore.editedPurchaseRow]);

  const closeDialog = (e) => {
    dispatch(toggleRowEditor(false));
  };

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  };

  const handleIncrement = () => {
    setCount((parseFloat(count) + 1).toString());
  };

  const handleDecrement = () => {
    setCount(count => (parseFloat(count) - 1).toString());
  };

  const handleIncrementQuarter = () => {
    setCount((count) => (parseFloat(count) + 0.25).toString());
  };

  const handleDecrementQuarter = () => {
    setCount(count => (parseFloat(count) - 0.25).toString());
  };

  const deleteRow = () => {
    dispatch(deletePurchaseRow(purchaseStore.editedPurchaseRow));
  };

  const save = () => {
    dispatch(updateRowCount(parseFloat(count.replace(',', '.'))));
  };

  return (
    <Dialog
      id="customer-dialog"
      onClose={closeDialog}
      aria-labelledby="dialog-title"
      open={purchaseStore.isRowEditorOpen}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        Muokkaa tuoteriviä
      </DialogTitle>
      <DialogContent style={{ paddingBottom: '1rem' }}>
        {rowData ? (
          <>
            <FormGroup>
              <FormLabel>Määrä</FormLabel>
              <ButtonGroup style={{ marginTop: '0.5rem' }} className="edit-row-count">
                <Button onClick={handleDecrement}>-</Button>
                <Button onClick={handleDecrementQuarter}>-1/4</Button>
                <TextField style={{ color: '#101010', flex: 1, textAlign: 'center' }}
                           inputProps={{ style: { textAlign: 'center' } }} // the change is here
                           value={count} onChange={setValue} type={'number'}/>
                <Button onClick={handleIncrementQuarter}>1/4+</Button>
                <Button onClick={handleIncrement}>+</Button>
              </ButtonGroup>
            </FormGroup>
          </>
        ) : <></>}
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={4}>
            <Button variant="contained" className="btn-danger btn" onClick={deleteRow}>Poista tuote</Button>
          </Grid>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            <Button variant="text" onClick={closeDialog} style={{ marginRight: '1rem' }}>
              Peruuta
            </Button>
            <Button variant="contained" color="primary" onClick={save}>
              Tallenna
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditPurchaseRowDialog;