import React, { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormGroup,
  TextField,
  Chip
} from '@material-ui/core';

import { getGiftCardBalance } from '../assets/js/api';
import { calculatePurchaseTotalSum, createManualPayment, formatCurrency } from '../assets/js/helpers';
import { RootState } from '../store/store';
import { addDiscount, completePurchase, sendPaymentData, startPurchase } from '../store/app/purchasesSlice';
import { toggleGiftCardDialog } from '../store/app/appSlice';
import { PaymentMethod } from '../types/types';

const AddGiftCard: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const [giftCardCode, setGiftCardCode] = useState<string | undefined>(undefined);
  const [balance, setBalance] = useState<number | undefined>(undefined);
  const [saveButtonText, setSaveButtonText] = useState<string>('Lisää alennus');

  const appStore = useSelector((state: RootState) => state.app);
  const products = useSelector((state: RootState) => state.products.products);
  const purchases = useSelector((state: RootState) => state.purchases);
  const selectedPurchase = purchases.purchases.find(purchase => purchase.id === purchases.selectedPurchase);
  const { sum } = calculatePurchaseTotalSum(selectedPurchase, products);

  const handleClose = (): void => {
    clearState();
    dispatch(toggleGiftCardDialog(false));
  };

  const handleInputchange = (e: React.ChangeEvent<HTMLInputElement>) => setGiftCardCode(e.target.value);

  const onCheckGiftCardBalance = async () => {
    if (giftCardCode) {
      const balanceRequest = await getGiftCardBalance(giftCardCode);

      if (balanceRequest?.summa) {
        setBalance(parseFloat(balanceRequest.summa));
      }
    }
  };

  useEffect(() => {
    if (balance >= sum) {
      setSaveButtonText('Maksa lahjakortilla');
    }
  }, [balance]);

  const clearState = (): void => {
    setGiftCardCode(undefined);
    setBalance(undefined);
    setSaveButtonText('Lisää alennus');
  };

  const handleAddDiscount = async () => {
    if (giftCardCode) {
      // Redo balance check so we're not using outdated data
      const { summa } = await getGiftCardBalance(giftCardCode);
      const balance = parseFloat(summa);

      if (balance > sum) {
        const payment = createManualPayment(appStore.cashRegistryId, PaymentMethod.GIFTCARD, sum);

        dispatch(addDiscount({ giftCardCode: giftCardCode, discount: sum }));

        dispatch(completePurchase({
          purchaseId: selectedPurchase.id,
          paymentData: {
            ...payment,
            payment_device_id: null,
          }
        }));

        dispatch(sendPaymentData());

        setTimeout(() => {
          dispatch(startPurchase());
        }, 5000);
      } else {
        //  If balance < selected purchase total sum => mark as a discount and require payment via card or cash
        dispatch(addDiscount({ giftCardCode: giftCardCode, discount: balance }));
      }

      dispatch(toggleGiftCardDialog(false));// Dispatch to store
      clearState();
    } else {
      toast.warn('Lahjakortin koodi puuttuu');
    }
  };

  return (
    <Dialog onClose={ handleClose } aria-labelledby="simple-dialog-title" open={ appStore.giftCardDialogOpen }>
      <DialogTitle>Lisää lahjakortti</DialogTitle>
      <DialogContent style={ { paddingBottom: '1rem' } }>
        <FormGroup>
          <TextField onChange={ handleInputchange } label="Lahjakortin koodi" helperText="Lahjakortilla oleva summa lisätään alennuksena ostotapahtumaan." />
        </FormGroup>
        <Button variant="outlined" style={ { margin: '1rem 0 0' } } onClick={ onCheckGiftCardBalance }>
          Tarkista saldo
        </Button>
        { balance ?
          <Chip style={ { margin: '0.7rem 0 0 1rem' } } label={ `Kortilla oleva saldo: ${ formatCurrency(balance) }` } variant="outlined" /> : <></> }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={ handleClose } style={ { marginRight: '1rem' } }>
          Peruuta
        </Button>
        <Button variant="contained" color="primary" onClick={ handleAddDiscount } disabled={ !balance }>
          { saveButtonText }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGiftCard;