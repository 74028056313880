import { useEffect, useState, useRef } from 'react';
import { Divider, Grid, List, ListItem, Typography, Box } from '@material-ui/core';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Purchase } from '../store/app/purchasesSlice';

import { formatCurrency } from '../assets/js/helpers';
import { Item, Product } from '../store/app/productSlice';

const Receipt = ({ purchase, prefix, hidden }: { purchase: Purchase, prefix: string, hidden?: boolean }) => {
  const products = useSelector((state: RootState) => state.products.products);

  const [total, setTotal] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const ref = useRef(null);

  // Calculate selected purchase total sum and vat sums
  useEffect(() => {
    let totalSum = 0 as number;

    if (purchase && purchase.rows.length > 0) {
      purchase.rows.forEach(item => {
        const product = products.find(product => product.id === item.productId);

        if (product) {
          totalSum += product.price * item.count;
        }
      });
    }

    setTotal(totalSum);
  }, [products, purchase]);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [ref]);

  const Vats = (): React.ReactElement => {
    const vats = {
      '0': {
        total: 0,
        vat: 0,
      },
      '10': {
        total: 0,
        vat: 0
      },
      '14': {
        total: 0,
        vat: 0
      },
      '24': {
        total: 0,
        vat: 0
      }
    };

    if (purchase && purchase.rows.length > 0) {
      purchase.rows.forEach((item) => {
        const product: Product = products.find((product: Product) => product.id === item.productId);

        if (product) {
          product.items.forEach((productRowItem: Item) => {
            vats[productRowItem.vatPercentage].vat += (productRowItem.vat * productRowItem.amount) * item.count;
            vats[productRowItem.vatPercentage].total += (productRowItem.price * productRowItem.amount) * item.count;
          });
        }
      });
    }

    return (
      <div className={ 'vat-row' }>
        { Object.keys(vats).map((row) => vats[row].total > 0 ? (
          <Grid container key={`purchase-receipt-${ prefix }-vat-row-${row}`}>
            <Grid item xs={ 2 }>
              { parseInt(row) } %
            </Grid>
            <Grid item xs={ 3 } style={ { textAlign: 'right' } }>
              { formatCurrency(vats[row].total - vats[row].vat) }
            </Grid>
            <Grid item xs={ 3 } style={ { textAlign: 'right' } }>
              { formatCurrency(vats[row].vat) }
            </Grid>
            <Grid item xs={ 4 } style={ { textAlign: 'right' } }>
              { formatCurrency(vats[row].total) }
            </Grid>
          </Grid>
        ) : <></>) }
      </div>
    );
  };

  return (
    <div style={ { height: height, background: 'white' } } className={ 'receipt-wrapper' }>
      <div id={ `purchase-receipt-${ prefix }-${ purchase.id }` } style={ {
        width: '385px',
        height: 'auto',
        margin: '0 auto',
        padding: '1rem',
        display: hidden ? 'none' : 'block',
        fontWeight: 500
      } }
           ref={ ref }>
        <Typography variant={ 'h3' } style={ { marginBottom: '1rem' } }>YRITYS</Typography>
        <Typography component={ 'p' }>Utopia Club</Typography>
        <Typography component={ 'p' }>Y-tunnus: 1056074-9</Typography>
        <Typography component={ 'p' }>Nice-Home Ravintolat Oy</Typography>
        <Typography component={ 'p' }>{ typeof purchase.paymentDeviceIndex !== 'undefined' ? `KASSA ${ purchase.paymentDeviceIndex }` : 'KÄTEINEN' }</Typography>
        <Typography component={ 'p' }>Puh: 02 0778 0733</Typography>
        <Typography component={ 'p' }>{ purchase.updated }</Typography>
        <Typography component={ 'p' }>Kuitti { purchase.sequenceId }</Typography>
        <Typography variant={ 'h3' } style={ { margin: '1rem 0' } }>KASSAKUITTI</Typography>
        <List dense={ true } key={ `purchase-receipt-${ prefix }-${ purchase.id }-list` }>
          { purchase.rows.map((item, index) => {
            const product: Product = products.find(product => product.id === item.productId);

            return product ? (
              <ListItem key={ `purchase-receipt-${ prefix }-${item.productId}-${index}` } divider={ false } dense={ true } disableGutters={ true }>
                <Grid container>
                  <Grid item xs={ 6 }>
                    <strong>{ product.name }</strong>
                  </Grid>
                  <Grid item xs={ 2 }>
                    { item.count }
                  </Grid>
                  <Grid item xs={ 4 } style={ { textAlign: 'right' } }>
                    <Typography
                      component={ 'p' }>{ formatCurrency(product.price * item.count) }</Typography>
                    <Typography
                      component={ 'p' }>{ formatCurrency(product.vat * item.count) }</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ) : <></>;
          }) }
        </List>
        <List>
          <ListItem dense={ true } className={ 'total-row total-sum' } disableGutters={ true }>
            <Grid container>
              <Grid item xs={ 5 }>
                Yhteensä
              </Grid>
              <Grid item xs={ 4 } />
              <Grid item xs={ 3 } style={ { textAlign: 'right' } }>
                { total.toFixed(2).replace('.', ',') } &euro;
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense={ true } className={ 'total-row total-sum' } disableGutters={ true }>
            <Grid container>
              <Grid item xs={ 5 }>
                Veloitettu
              </Grid>
              <Grid item xs={ 4 } />
              <Grid item xs={ 3 } style={ { textAlign: 'right' } }>
                { total.toFixed(2).replace('.', ',') } &euro;
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense={ true } className={ 'total-row total-sum' } disableGutters={ true }>
            <Grid container>
              <Grid item xs={ 5 }>
                Maksettu
              </Grid>
              <Grid item xs={ 4 } />
              <Grid item xs={ 3 } style={ { textAlign: 'right' } }>
                { (purchase.paymentData.amount / 100).toFixed(2).replace('.', ',') } &euro;
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense={ true } className={ 'total-row total-sum' } disableGutters={ true }>
            <Grid container>
              <Grid item xs={ 5 }>
                Takaisin
              </Grid>
              <Grid item xs={ 4 } />
              <Grid item xs={ 3 } style={ { textAlign: 'right' } }>
                0,00 &euro;
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          { purchase.paymentData.customer_receipt.text.split('\n').map((line, index) =>
            <ListItem dense={ true } key={ `purchase-receipt-${ prefix }-customer-receipt-line-${index}` } disableGutters={ true }>{ line }</ListItem>)
          }
          <Divider />
          { typeof purchase.comment !== 'undefined' && purchase.comment !== '' ? (
            <>
              <Box style={ { padding: '1rem 0' } }>
                <Typography component={ 'p' }>{ purchase.comment }</Typography>
              </Box>
              <Divider />
            </>
          ) : <></> }
          <Grid container style={ { margin: '0.5rem 0' } }>
            <Grid item xs={ 2 }>ALV %</Grid>
            <Grid item xs={ 3 } style={ { textAlign: 'right' } }>ALV 0</Grid>
            <Grid item xs={ 3 } style={ { textAlign: 'right' } }>ALV</Grid>
            <Grid item xs={ 4 } style={ { textAlign: 'right' } }>YHTEENSÄ</Grid>
          </Grid>
          <Vats />
        </List>
      </div>
    </div>
  );
};

export default Receipt;