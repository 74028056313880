import WebSocketPos  from './WebSocketPos';

export default class Connection  {
  /**
   * New Connection
   *
   * @param {Object} args
   */
  constructor(args) {
    const { name, username, password, terminalId, apiKey, currency, webSocketPosMethods, stateChanged } = args;
    this.name = name;
    this.username = username;
    this.password = password;
    this.terminalId = terminalId;
    this.stateChanged = stateChanged;
    this.webSocketPosMethods = webSocketPosMethods;
    this.currency = !currency || currency.length !== 3 ? 'EUR' : currency;
    this.apiKey = apiKey;
    this.connection = null;

    if (typeof this.webSocketPosMethods !== 'object') {
      this.webSocketPosMethods = {};
    }
  }

  /**
   * Disconnect from terminal
   */
  disconnect() {
    if (this.connection) {
      this.connection.close('Replaced by new connection');
      this.connection = null;
    }
  }

  /**
   * Connect to terminal using CloudPos. Already existing connections will be terminated.
   *
   * @returns {WebSocketPos}
   */
  connect () {
    this.disconnect();

    // @ts-ignore
    this.connection = new WebSocketPos({
      cloudpos: true,
      user: this.username,
      password: this.password,
      terminalId: this.terminalId,
      stateChanged: typeof this.stateChanged === 'function' ? this.stateChanged : (state, message) => console.log('Connection state:', message),
      methods: {
        PosMessage: function (message, success) {
          console.log('PosMessage:', message);
          success({});
        },
        StatusEvent: function (message, success) {
          console.log('StatusEvent:', message);
          success({});
        },
        ...this.webSocketPosMethods
      },
    });

    return this.connection;
  }

  /**
   * Dispatches a request to the terminal and executes designated callback functions
   * for success and error events
   *
   * @param method string 'TerminalInfo', 'Status', 'Abort', 'Suspend', 'Reboot', 'Purchase'
   * @param request Request arguments
   * @param successCb
   * @param errorCb
   * @returns {void}
   */
  sendRequest (method, request, successCb, errorCb) {
    if(this.connection) {
      this.connection.sendRequest(method, request, function (result) {
        console.log('Last result', JSON.stringify(result, null, 4));

        if (successCb) {
          successCb(result);
        }
      }, function (error) {
        try {
          console.error('Last error', error.data.details);
        } catch (e) {
          console.error(e);
        }

        if (errorCb) {
          errorCb(error);
        }
      });
    }
  }

  /**
   * Creates basic request arguments
   *
   * @returns {{api_key, timestamp: string}}
   */
  createRequest (requestArgs) {
    return Object.assign({}, {
      timestamp: new Date().toISOString(),
      api_key: this.apiKey,
    }, requestArgs);
  }

  /**
   * Dispatch a request to the terminal
   *
   * @param method string 'TerminalInfo', 'Status', 'Abort', 'Suspend', 'Reboot', 'Purchase'
   * @param request object
   * @param successCb callback function for successful response
   * @param errorCb callback function for failed response
   * @returns {boolean}
   */
  request (method, request, successCb, errorCb) {
    if (!this.connection) {
      return false;
    }

    return this.sendRequest(method, this.createRequest(typeof request === 'object' ? request : {}), successCb, errorCb);
  }
}