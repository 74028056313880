import React, { ReactElement, useEffect, useState } from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToOpenPurchase, Purchase, PurchaseStates } from '../store/app/purchasesSlice';

import { RootState } from '../store/store';
import { Item, toggleAmountSelectorDialog } from '../store/app/productSlice';

import { formatCurrency } from '../assets/js/helpers';
import useLongPress from '../hooks/useLongPress';
import { toast } from 'react-toastify';

const ProductCard: React.FC<{
  title: string,
  id: string,
  items: Array<Item>,
  vat: number,
  price: number,
  backgroundColor: string
}> = (props): ReactElement => {
  const { title, id, items, price, backgroundColor } = props;

  const dispatch = useDispatch();
  const products = useSelector((state: RootState) => state.products.products);
  const purchasesState = useSelector((state: RootState) => state.purchases);
  const [openPurchase, setOpenPurchase] = useState<Purchase>(null);

  useEffect(() => {
    const purchaseIndex = purchasesState.purchases.findIndex(purchase => {
      return purchase.id === purchasesState.selectedPurchase;
    });

    if (purchaseIndex > -1) {
      setOpenPurchase(purchasesState.purchases[purchaseIndex]);
    }

  }, [purchasesState.purchases, purchasesState.selectedPurchase]);

  const openSomeDialog = (): void => {
    const product = products.find(product => product.id === parseInt(id));

    if (product) {
      dispatch(toggleAmountSelectorDialog({ open: true, productId: product.id }));
    }
  };

  const addProductToPurchase = (): void => {
    const product = products.find(product => product.id === parseInt(id));

    if (product && openPurchase && openPurchase.status === PurchaseStates.OPEN) {
      dispatch(addProductToOpenPurchase({ id: parseInt(id) }));
    } else {
      toast.warn('Et voi lisätä tuotteita jo lopetettuun ostotapahtumaan', {
        toastId: 'unable-to-add-product-to-purchase',
        autoClose: 3000
      });
    }
  };

  const backspaceLongPress = useLongPress({ callback: openSomeDialog, ms: 1000 });

  return (
    <Card style={ { borderRadius: 0, background: 'transparent', backgroundColor, height: '100%' } } className={ 'product-card' } elevation={ 1 } onClick={ addProductToPurchase } { ...backspaceLongPress }>
      <CardActionArea>
        <CardContent style={ { padding: '1rem' } }>
          <Typography variant="h2" component="h2" style={ { marginBottom: 0 } }>
            { title }
          </Typography>
          <Typography variant="h3" component="h3" style={ { marginBottom: 0 } }>
            { formatCurrency(price) }
          </Typography>
          <Typography component={'small'} className={'grey-1'}>
            { items.map((item) => item.name).join(', ') }
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;