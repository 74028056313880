import React, { useEffect } from 'react';

// UI
import {
  Grid, LinearProgress,
  Paper,
} from '@material-ui/core';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { AppState, resetTerminalConnections, setSelectedCategory } from '../store/app/appSlice';

// Components
import Categories from '../components/Categories';
import PurchaseBar from '../components/PurchaseBar/Bar';
import Search from '../components/Search';

// Libraries
import AddProductToPurchaseWithAmount from '../components/AddProductToPurchaseWithAmount';

import { useFetchDataFromAPI } from '../hooks/useFetchDataFromAPI';
import { useConnectToPaymentDevice } from '../hooks/useConnectToPaymentDevice';
import AddGiftCard from '../components/AddGiftCard';
import ProducGroups from '../components/ProducGroups';
import { PurchaseSliceState, startPurchase } from '../store/app/purchasesSlice';
import { toast } from 'react-toastify';
import AddDiscount from '../components/AddDiscount';
import { isAuthenticated } from '../assets/js/auth';
import { useRouter } from 'next/router';

const Home = (): React.ReactElement => {
    const router = useRouter();

    if (!isAuthenticated()) {
      router.push('/login');
      return <></>;
    }

    const dispatch = useDispatch();
    // State
    const groups = useSelector((state: RootState) => state.products.productGroups);
    const appState: AppState = useSelector((state: RootState) => state.app);
    const purchases: PurchaseSliceState = useSelector((state: RootState) => state.purchases);

    // Fetch connections to payment devices
    const connections = useConnectToPaymentDevice();

    // Fetch product data from API to state
    const { loading } = useFetchDataFromAPI();

    useEffect(() => {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side');

      if (jssStyles && jssStyles.parentElement) {
        jssStyles.parentElement.removeChild(jssStyles);
      }

      if (purchases.purchases.length === 0) {
        dispatch(startPurchase());
      }

      const persistedTerminalConnections = appState.usedTerminalConnections;

      if (persistedTerminalConnections.length > 0 && connections) {
        persistedTerminalConnections.forEach((terminalId) => {
          const connection = connections.find(connection => connection.terminalId === terminalId);

          if (connection) {
            connection.connect();
          }
        });
      }

      dispatch(resetTerminalConnections());
    }, []);

    useEffect(() => {
      if (!appState.cashRegistryId) {
        toast.warn('Ole hyvä ja täytä kassalaitteen tunnus asetuksista ennen maksutapahtuman käsitelyä!', {
          delay: 0
        });
      }
    }, [appState.cashRegistryId]);

    /**
     * Change category
     *
     * @param event
     * @param newValue
     */
    const handleChange = (event: React.MouseEvent, newValue: number): void => {
      dispatch(setSelectedCategory(newValue));
    };

    return (
      <div className="container" id="root-container">
        { loading ? <LinearProgress /> : (
          <Grid container spacing={ 0 } className={ 'height-100-vh' }>
            <Grid item xs={ 9 } style={ { position: 'relative' } }>
              <Categories handleChange={ handleChange } value={ appState.selectedCategoryIndex } tabs={ groups } />
              <ProducGroups groups={ groups } />
              <Search />
            </Grid>
            <Grid container item xs={ 3 } className={ 'sidebar' } style={ { height: '100%' } }>
              <Paper style={ { width: '100%', minHeight: '100%' } } elevation={ 0 }>
                <PurchaseBar connections={ connections } />
              </Paper>
            </Grid>
          </Grid>
        ) }
        <AddProductToPurchaseWithAmount />
        <AddGiftCard />
        <AddDiscount />
      </div>
    );
  }
;

export default Home;