import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';

import { getContrastYIQ } from '../assets/js/helpers';

import { Product, ProductGroup } from '../store/app/productSlice';
import { RootState } from '../store/store';
import TabPanel from './TabPanel';
import ProductCard from './ProductCard';

interface ProductGroupProps {
  groups: ProductGroup[]
}

const ProducGroups: React.FC<ProductGroupProps> = ({ groups }): React.ReactElement => {
  const appState = useSelector((state: RootState) => state.app);

  return (
    <>
      {
        (groups ? groups.map((group: ProductGroup, index: number) => (
          <TabPanel value={ appState.selectedCategoryIndex } index={ index } key={ 'tab-panel-' + index }>
            <Grid container style={ { padding: '1rem', maxHeight: 'calc(100vh - 100px)', overflow: 'auto' } }>
              { group.products && group.products.length > 0 ? group.products.map((product: Product, index) => (
                <Grid item xs={ 2 }
                      className={ `product-card-container ${ product.bgColor ? getContrastYIQ(product.bgColor) : 'color-black' }` }
                      style={ { padding: '0.25rem', boxSizing: 'border-box' } }
                      key={ `tab-${ group.id }-product-${ product.id }-${ index }` }>
                  <ProductCard title={ product.name } id={ product.id.toString() } items={ product.items }
                               price={ product.price } vat={ product.vat } backgroundColor={ product.bgColor ? product.bgColor : '#FFF' } />
                </Grid>
              )) : (
                <Grid item xs={ 12 } style={ { textAlign: 'center' } }>
                  <Typography style={ { textAlign: 'center' } }>
                    <SentimentDissatisfiedIcon fontSize={ 'large' } /><br />
                    Ei tuotteita
                  </Typography>
                </Grid>
              ) }
            </Grid>
          </TabPanel>
        )) : <></>)
      }
    </>
  );
};

export default ProducGroups;