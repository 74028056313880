import { Purchase } from '../../store/app/purchasesSlice';
import { Product } from '../../store/app/productSlice';
import { Payment, PaymentMethod } from '../../types/types';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

export const printVat = (vat: Record<string, number>): string => {
  if (typeof vat['24'] !== 'undefined') {
    return vat['24'].toString();
  } else if (typeof vat['14'] !== 'undefined') {
    return vat['14'].toString();
  } else if (typeof vat['10'] !== 'undefined') {
    return vat['10'].toString();
  }

  return '0';
};

export const formatNumber = (number: number, decimals = 2): string => {
  return number.toLocaleString('fi-Fi', { maximumFractionDigits: decimals, minimumFractionDigits: decimals });
};

export const formatCurrency = (number: number, decimals = 2, currency = 'EUR'): string => {
  const opts = {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  };

  if (currency) {
    opts['currency'] = currency;
    opts['style'] = 'currency';
  }

  return number.toLocaleString('fi-Fi', opts);
};

/**
 * Format receipt text for cash payments
 *
 * @param total
 * @param reference
 * @param transactionId
 */
export const formatReceiptText = (total: number, transactionId: number) => {
  return `Veloitus ${formatCurrency(total)} EUR\nMaksutapa: KÄTEINEN\nViite: ${createReference(transactionId.toString())}`
}

/**
 * Create a reference from provided transaction ID (usually timestamp in format yyyymmddhhiiss)
 *
 * @param transactionId
 */
export const createReference = (transactionId: string): string => {
  const multiplier = '731';
  let checkSum = 0;
  let formatting = '';

  for (let i = transactionId.length - 1, j = 0, k = 1; i >= 0; i--, j++, k++) {
    const char = transactionId.charAt(i);
    checkSum += parseInt(multiplier.charAt(j % 3), 10) * parseInt(char);
    formatting = char + (k % 5 === 0 ? ' ' + formatting : formatting);
  }

  return formatting + ((10 - checkSum % 10) % 10).toString();
}

interface Vats {
  '10': number,
  '14': number,
  '24': number,
}


export function calculatePurchaseTotalSum(selectedPurchase: Purchase, products: Product[]):{
  sum: number,
  vats: Vats,
  discountSum: number,
} {
  let totalSum = 0;
  let discountSum = 0;
  const vats = { '24': 0.00, '14': 0.00, '10': 0.00 };

  if (selectedPurchase && selectedPurchase.rows.length > 0) {
    selectedPurchase.rows.forEach(item => {
      const product = products.find(product => product.id === item.productId);

      if (product) {
        totalSum += product.price * item.count;

        if (typeof product.vat['24'] !== 'undefined') {
          vats['24'] += product.vat['24'] * item.count;
        }

        if (typeof product.vat['14'] !== 'undefined') {
          vats['14'] += product.vat['14'] * item.count;
        }

        if (typeof product.vat['10'] !== 'undefined') {
          vats['10'] += product.vat['10'] * item.count;
        }
      }
    });

    if (selectedPurchase.discount) {
      totalSum -= selectedPurchase.discount;
    } else if (selectedPurchase.discountPercentage) {
      discountSum = totalSum - (totalSum * ((100 - selectedPurchase.discountPercentage) / 100));
      totalSum = totalSum - discountSum;
    }

  }
  return { sum: totalSum, vats: vats, discountSum };
}

/**
 * Create a manual payment object for cash / gift card payments
 *
 * @param cashRegistryId
 * @param paymentMethod
 * @param total
 */
export function createManualPayment(cashRegistryId: string, paymentMethod: PaymentMethod, total: number) {
  const transactionId = parseInt(format(new Date(), 'yyMMddHHmmss'));

  // Create a payment for the full sum and generate unique id
  const payload: Payment = {
    cash_registry: cashRegistryId,
    merchant_receipt: {
      text: formatReceiptText(total, transactionId)
    },
    payment_device_id: null,
    payment_method: paymentMethod,
    currency: 'EUR', // Todo: Make it possible to have multiple currencies
    amount: (total * 100),
    transaction_unique_id: uuidv4(),
    transaction_time: transactionId,
    customer_receipt: {
      'text': formatReceiptText(total, transactionId)
    },
    product_rows: undefined
  };

  return payload;
}

export function getContrastYIQ(hexcolor: string) {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return 'color-' + ((yiq >= 128) ? 'black' : 'white');
}