import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { formatNumber, formatCurrency, calculatePurchaseTotalSum } from '../../assets/js/helpers';

// UI
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListSubheader, Menu, MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  CardGiftcard,
  ExitToApp,
  Fullscreen,
  FullscreenExit,
  MoneyOff,
  MoreVert,
  Receipt,
  Settings
} from '@material-ui/icons';

// State management
import {
  Purchase,
  PurchaseStates,
  setEditedPurchaseRow,
  setSelectedPurchase as setSelectedPurchaseToStore,
  startPurchase,
  toggleReceiptDialog,
  toggleRowEditor,
  deletePurchase, removeDiscountPercentage
} from '../../store/app/purchasesSlice';
import { toggleEditTerminalDialog } from '../../store/app/terminalSlice';
import { RootState } from '../../store/store';

// Components
import EditPurchaseRowDialog from '../EditPurchaseRowDialog';
import ReceiptsDialog from '../ReceiptsDialog';
import EditMachineSettings from '../EditMachineSettings';
import PaymentButtons from './PaymentButtons';
import Connection from '../../assets/js/lib/Connection';
import { toast } from 'react-toastify';
import { setSelectedCategory, toggleDiscountDialog, toggleGiftCardDialog } from '../../store/app/appSlice';
import ConfirmDialog from '../ConfirmDialog';
import { removeToken } from '../../assets/js/auth';
import { useRouter } from 'next/router';

interface Document {
  exitFullscreen: () => void;
  mozCancelFullScreen: () => void;
  webkitExitFullscreen: () => void;
  fullscreenElement: () => void;
  mozFullScreenElement: () => void;
  webkitFullscreenElement: () => void;
}

const PurchaseBar = ({ connections }: { connections: Connection[] }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  // State
  const app = useSelector((state: RootState) => state.app);
  const purchases = useSelector((state: RootState) => state.purchases);
  const products = useSelector((state: RootState) => state.products.products);
  const devices = useSelector((state: RootState) => state.terminal.devices);

  // Hooks

  const [total, setTotal] = useState<number>(0.00);
  const [discountAmount, setDiscountAmount] = useState<number>(0.00);
  const [vat, setVat] = useState<{ '24': number, '14': number, '10': number }>({ '24': 0.00, '14': 0.00, '10': 0.00 });
  const [purchaseList, setPurchaseList] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState<Purchase>(null);
  const [isFullscreen, setFullscreen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeletePurchaseEventDialogOpen, setDeletePurchaseDialogOpen] = useState<boolean>(false);

  // On mounted set selected purchase, if one exists
  useEffect(() => {
    if (purchases.purchases.length > 0) {
      dispatch(setSelectedPurchaseToStore(purchases.purchases[0].id));
    }
  }, []);

  const toggleDeletePurchaseEventDialog = (open: boolean) => {
    setDeletePurchaseDialogOpen(open);
  };

  const handleSubmitDeletePurchaseEvent = () => {
    dispatch(deletePurchase(purchases.selectedPurchase));
  };

  // Calculate selected purchase total sum and vat sums once selectedPurchase is set
  useEffect(() => {
    const selectedPurchase = purchases.purchases.find(purchase => purchase.id === purchases.selectedPurchase);

    if (selectedPurchase) {
      setSelectedPurchase(selectedPurchase);
    }
  }, [purchases.selectedPurchase, purchases.purchases]);

  // When purchase list changes
  useEffect(() => {
    if (purchases.purchases.length > 0) {
      // Only grab the open ones
      const purchaseList = [...purchases.purchases];
      const reversed = purchaseList.reverse();
      setPurchaseList(reversed);
    }
  }, [purchases.purchases]);

  // Calculate selected purchase total sum and vat sums
  useEffect(() => {
    let { sum, vats, discountSum } = calculatePurchaseTotalSum(selectedPurchase, products);
    setTotal(sum);
    setVat(vats);
    setDiscountAmount(discountSum);
  }, [selectedPurchase]);

  const addNewPurchase = () => {
    if (!app.cashRegistryId) {
      toast.warn('Ole hyvä ja täydennä kassalaitteen tunnus asetuksiin ensin!');
    } else {
      dispatch(setSelectedCategory(0));
      dispatch(startPurchase());
    }
  };

  const openRowEditDialog = (rowNum): void => {
    if (selectedPurchase.status === PurchaseStates.OPEN) {
      dispatch(setEditedPurchaseRow(rowNum));
      dispatch(toggleRowEditor(true));
    } else {
      toast.warn('Et voi muokata tuotteita lopetetussa ostotapahtumassa', {
        toastId: 'unable-to-add-product-to-purchase',
        autoClose: 3000
      });
    }
  };

  const handleOpenAppMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openTerminalEditDialog = () => {
    dispatch(toggleEditTerminalDialog(true));
    setAnchorEl(null);
  };

  // Hides and shows active product category tab when full screen gets toggled on / off
  const toggleTabVisibility = (index?: number) => {
    const tabs = document.getElementsByClassName('product-category-tab-panel');
    let visibleIndex = index ?? null;

    if (visibleIndex !== null) {
      tabs[visibleIndex].classList.remove('hidden');
    } else {
      for (let i = 0; i < tabs.length; i++) {
        const style = window.getComputedStyle(tabs[i]);

        if (style.display !== 'none') {
          tabs[i].classList.add('hidden');
          visibleIndex = i;
        }
      }
    }

    return visibleIndex;
  };

  const toggleFullscreen = () => {
    const index = toggleTabVisibility();
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen = docEl['requestFullscreen'] || docEl['mozRequestFullScreen'] || docEl['webkitRequestFullScreen'] || docEl['msRequestFullscreen'];
    const cancelFullScreen = doc['exitFullscreen'] || doc['mozCancelFullScreen'] || doc['webkitExitFullscreen'] || doc['msExitFullscreen'];

    if (!doc['fullscreenElement'] && !doc['mozFullScreenElement'] && !doc['webkitFullscreenElement'] && !doc['msFullscreenElement']) {
      setFullscreen(true);
      requestFullScreen.call(docEl);
    } else {
      setFullscreen(false);
      cancelFullScreen.call(doc);
    }

    toggleTabVisibility(index);
    setAnchorEl(null);
  };

  const openReceiptDialog = () => {
    dispatch(toggleReceiptDialog(true));
    setAnchorEl(null);
  };

  const openGiftCardDiscountDialog = (): void => {
    dispatch(toggleGiftCardDialog(true));
    setAnchorEl(null);
  };

  const openDiscountDialog = (): void => {
    dispatch(toggleDiscountDialog(true));
    setAnchorEl(null);
  };

  const signOut = () => {
    removeToken();
    router.push('/login');
  }

  return (
    <>
      <div id={ 'purchase-bar' }>
        <AppBar position="static" color="default" className={ 'action-bar' } elevation={ 1 }>
          <Toolbar variant={ 'dense' } disableGutters>
            <Grid container spacing={ 0 }>
              <Grid item xs className="purchases" style={ { flex: 1 } }>
                { purchaseList.map(purchase => (
                  <Button variant={ 'outlined' } color={ 'primary' } key={ 'purchase-link-' + purchase.sequenceId }
                          onClick={ () => dispatch(setSelectedPurchaseToStore(purchase.id)) }
                          className={ `${ selectedPurchase && selectedPurchase.id === purchase.id ? 'active' : '' }` }>{ purchase.title }</Button>
                )) }
              </Grid>
              <Grid item xs style={ {
                textAlign: 'right',
                flex: '0 0 50px',
                paddingBottom: '10px'
              } } className="machine-settings-button-wrapper">
                <Button
                  variant="outlined"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={ handleOpenAppMenu }
                >
                  <MoreVert />
                </Button>
              </Grid>
            </Grid>
            <Menu
              id="simple-menu"
              anchorEl={ anchorEl }
              keepMounted
              open={ Boolean(anchorEl) }
              onClose={ handleClose }
            >
              <MenuItem onClick={ openReceiptDialog }>
                <Receipt />
                Kuitit
              </MenuItem>
              { connections.length > 0 ? (
                <MenuItem onClick={ openTerminalEditDialog }>
                  <Settings /> Asetukset
                </MenuItem>
              ) : <></> }
              <MenuItem onClick={ toggleFullscreen }>
                <Fullscreen /> { isFullscreen ? 'Koko ruutu POIS' : 'Koko ruutu PÄÄLLE' }
              </MenuItem>
              <MenuItem
                onClick={ openGiftCardDiscountDialog }
                disabled={ selectedPurchase?.giftCardCode && selectedPurchase?.giftCardCode !== '' }
              >
                <CardGiftcard /> Syötä lahjakortti
              </MenuItem>
              <MenuItem onClick={ openDiscountDialog }>
                <MoneyOff /> Syötä alennus
              </MenuItem>
              <MenuItem onClick={ signOut }>
                <ExitToApp /> Kirjaudu ulos
              </MenuItem>
            </Menu>
          </Toolbar>
          <Toolbar>
            <Button variant={ 'contained' } color={ 'primary' } onClick={ addNewPurchase }>
              Uusi osto
            </Button>
            <Button variant={ 'contained' } color={ 'primary' } onClick={ () => toggleDeletePurchaseEventDialog(true) } style={ { marginLeft: '1rem' } }>
              Poista osto
            </Button>
          </Toolbar>
        </AppBar>
        { selectedPurchase ? (
          <Grid container direction={ 'column' } className={ 'purchase-list' } wrap={ 'nowrap' }>
            <Grid item className={ 'purchase-list-items' }>
              <List>
                <ListSubheader key="selected-purchase-item-subheader">
                  OSTO { selectedPurchase.title }
                </ListSubheader>
                <ListItem divider={ false } button={ true } className={ 'list-header' } key="selected-purchase-item-title-row">
                  <Grid container>
                    <Grid item xs={ 8 }>
                      TUOTE
                    </Grid>
                    <Grid item xs={ 4 } style={ { textAlign: 'right' } }>
                      SUMMA (&euro;)
                    </Grid>
                  </Grid>
                </ListItem>
                { selectedPurchase.rows.map((item, index) => {
                  const product = products.find(product => product.id === item.productId);

                  return product ? (
                    <ListItem key={ selectedPurchase.sequenceId + item.productId + index } divider={ false } button={ true }
                              onClick={ () => openRowEditDialog(index) }>
                      <Grid container>
                        <Grid item xs={ 2 }>
                          <Avatar>{ product.name.substr(0, 1) }</Avatar>
                        </Grid>
                        <Grid item xs={ 6 }>
                          <Link><strong>{ product.name }</strong></Link>
                          <div>{ formatNumber(item.count) }</div>
                        </Grid>
                        <Grid item xs={ 4 } style={ { textAlign: 'right' } }>
                          <Typography component={ 'p' } variant={ 'subtitle1' }
                                      className={ 'product-price' }>{ formatCurrency((product.price * item.count), 2, '') }</Typography>
                          <Typography
                            component={ 'small' }>{ formatCurrency((parseFloat(product.vat) * item.count), 2, '') }</Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ) : <></>;
                }) }
                { selectedPurchase.discountPercentage ? (
                  <ListItem key="selected-purchase-item-discount-percentage">
                    <Grid container>
                      <Grid item xs={ 2 }>
                        <Avatar>A</Avatar>
                      </Grid>
                      <Grid item xs={ 6 }>
                        <Link><strong>Alennus ostosta</strong></Link>
                        <div>-{ selectedPurchase.discountPercentage }%</div>
                      </Grid>
                      <Grid item xs={ 4 } style={ { textAlign: 'right' } }>
                        <Typography component={ 'p' } variant={ 'subtitle1' }
                                    className={ 'product-price' }>- { formatCurrency(discountAmount, 2, '') }</Typography>
                        <Link
                          component="button" onClick={ () => dispatch(removeDiscountPercentage()) } variant="body1">Poista alennus</Link>
                      </Grid>
                    </Grid>
                  </ListItem>
                ) : <></> }
                { selectedPurchase.discount ? (
                  <ListItem key="selected-purchase-item-discount">
                    <Grid container>
                      <Grid item xs={ 2 }>
                        <Avatar>A</Avatar>
                      </Grid>
                      <Grid item xs={ 6 }>
                        <Link><strong>Alennus</strong></Link>
                        <div>{ formatNumber(1) }</div>
                      </Grid>
                      <Grid item xs={ 4 } style={ { textAlign: 'right' } }>
                        <Typography component={ 'p' } variant={ 'subtitle1' }
                                    className={ 'product-price' }>- { formatCurrency(selectedPurchase.discount, 2, '') }</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ) : <></> }
              </List>
            </Grid>
          </Grid>
        ) : <div style={ { padding: '1rem' } }><Typography align={ 'center' }>Ei avoimia ostotapahtumia.</Typography>
        </div> }
        { selectedPurchase ? (
          <List id="purchase-total-sum">
            <ListItem dense={ false } className={ 'total-row total-sum' } key="selected-purchase-item-tally">
              <Grid container>
                <Grid item xs={ 5 }>
                  Maksettava
                </Grid>
                <Grid item xs={ 4 } />
                <Grid item xs={ 3 } style={ { textAlign: 'right' } }>
                  <Typography>{ total.toFixed(2).replace('.', ',') } &euro;</Typography>
                </Grid>
              </Grid>
            </ListItem>
            { Object.keys(vat).map((vatpr, index) => {
              if (vat[vatpr] === 0) {
                return false;
              }

              return (
                <ListItem dense={ false } className={ 'total-row' } key={ vatpr + index }>
                  <Grid container>
                    <Grid item xs={ 5 }>
                      ALV { vatpr }%
                    </Grid>
                    <Grid item xs={ 4 } />
                    <Grid item xs={ 3 } style={ { textAlign: 'right' } }>
                      <Typography>{ vat[vatpr].toFixed(2).replace('.', ',') } &euro;</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            }) }
          </List>
        ) : <></> }
        { !isEmpty(devices) ? (
          <>
            <PaymentButtons connections={ connections } />
            <EditPurchaseRowDialog />
            <ReceiptsDialog connections={ connections } />
            <EditMachineSettings connections={ connections } />
          </>
        ) : <></> }
      </div>
      <ConfirmDialog
        title={ 'Poista ostotapahtuma' }
        onSubmit={ handleSubmitDeletePurchaseEvent }
        toggleOpen={ toggleDeletePurchaseEventDialog }
        open={ isDeletePurchaseEventDialogOpen }
      >
        Oletko varma, että haluat poistaa tämän ostotapahtuman?
      </ConfirmDialog>
    </>
  );
};

export default PurchaseBar;