import { Product } from '../store/app/productSlice';
import { PurchaseProductRow } from '../store/app/purchasesSlice';

export interface Tab {
  id: string;
  nimi: string;
}

export interface APITabItem extends Tab {}

export interface APIProductItem {
  id: string;
  nimi: string;
  hinta: string;
  vero: '24.00' | '14.00' | '10.00';
  alv: string;
  myyntiera: string;
}

interface APITabProductRelationship {
  tuote_id: string;
  maara: string;
}

export interface APITabProductItem {
  nappi_id: string;
  tabi_id: string;
  nimi: string;
  color: string;
  prods: null | Array<APITabProductRelationship>
}

interface NonPaymentData {
  type: string;
  value: string;
  token_type: string;
  store: boolean;
}

export enum PaymentMethod {
  CASH = 'cash',
  GIFTCARD = 'gift_card',
  CARD = 'card',
}

export interface Payment {
  transaction_unique_id: string;
  merchant_receipt: {
    text: string;
    signature_required?: boolean;
    id_check_required?: boolean;
  },
  payment_device_id: string | null;
  currency: string;
  giftcard?: {
    code: string;
    amount: number;
  };
  discountPercentage?: number;
  amount: number;
  transaction_time: number;
  customer_receipt: {
    text: string;
  }
  cash_registry: string;
  payment_method: PaymentMethod,
  transaction_id?: string;
  pan_masked_for_clerk?: string;
  bypass_pin?: boolean;
  pan_masked_for_customer?: null | string;
  card_reading_method?: 'mag' | 'chip';
  card_name?: null | string;
  response_text?: string;
  response_code?: string;
  merchant_number?: number;
  contract_valid_for_schemes?: string[];
  forced_authorization?: boolean;
  non_payment_data?: Array<NonPaymentData>;
  product_rows: Array<PurchaseProductRow> | undefined;
}