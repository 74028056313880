import { AppBar, Tab, Tabs } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategory } from '../store/app/appSlice';
import { RootState } from '../store/store';

const Categories = ({ tabs }: { value: number, handleChange: Function, tabs: Array<{ name: string, id: number}>}) => {
  const dispatch = useDispatch();
  const appState = useSelector((state: RootState) => state.app);

  const changeCategory = (e: React.ChangeEvent, value: number) => {
    dispatch(setSelectedCategory(value));
  }

  return (
    <AppBar position="static" color="primary" elevation={1}>
      <Tabs
        className="category-tabs"
        value={appState.selectedCategoryIndex}
        onChange={changeCategory}
        variant="scrollable"
        scrollButtons="auto"
      >
        { tabs.map(label => (
          <Tab label={label.name} key={label.id} />
        ))}
      </Tabs>
    </AppBar>
  );
}

export default Categories;