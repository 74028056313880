import { useState, useEffect } from 'react';

const useLongPress = (props: { callback: () => void, ms: number }) => {
  const [startLongPress, setStartLongPress] = useState(false);
  const defaults = { callback: () => {}, ms: 300 };
  const args = Object.assign({}, defaults, props);

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(args.callback, args.ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [args.callback, args.ms, startLongPress]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
};

export default useLongPress;