import React from 'react';
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

interface ConfirmDialogProps {
  onSubmit: () => void,
  toggleOpen: (open: boolean) => void,
  open: boolean,
  children?: React.ReactNode;
  title: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ title, onSubmit, open, toggleOpen, children }) => {
  const handleClose = () => {
    toggleOpen(false);
  };

  const handleSubmit = () => {
    onSubmit();
    toggleOpen(false);
  };

  return (
    <div>
      <Dialog
        open={ open }
        onClose={ handleClose }
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={ { cursor: 'move' } } id="draggable-dialog-title">
          { title }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { children }
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirm-dialog-actions">
          <Button autoFocus onClick={ handleClose } size="large">
            Peruuta
          </Button>
          <Button onClick={ handleSubmit } color="primary" variant="contained" size="large">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;