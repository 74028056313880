import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Chip,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography, FormLabel, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import { AttachMoney, SettingsInputAntenna } from '@material-ui/icons';

// State management
import { persistor } from '../store/store';
import { TerminalSliceState, TerminalStatus, toggleEditTerminalDialog, updateStatus } from '../store/app/terminalSlice';
import Connection from '../assets/js/lib/Connection';
import { RootState } from '../store/store';
import {
  AppState,
  removeUsedTerminalConnection,
  setCashRegistryId,
  setUsedTerminalConnection
} from '../store/app/appSlice';

const EditMachineSettings = ({ connections }: { connections: Array<Connection> }) => {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Component state
  const [logEntries, setLogEntries] = useState('');
  const [terminalId, setTerminalId] = useState<string>('');

  // Store
  const appStore: AppState = useSelector((state: RootState) => state.app);
  const terminalStore: TerminalSliceState = useSelector((state: RootState) => state.terminal);
  const paymentDevice = terminalStore.devices[terminalId];

  /**
   * Close dialog
   */
  const closeDialog = () => {
    dispatch(toggleEditTerminalDialog(false));
  };

  /**
   * Update and display device status
   */
  const showStatus = () => {
    const connection = connections.find((c) => c.terminalId === terminalId);

    if (connection) {
      connection.request('Status', {}, (message: TerminalStatus) => {
        dispatch(updateStatus({ status: message, terminalId: connection.terminalId }));
        toast.info('Maksupäätteen tila päivitetty');
      });

      connection.request('TerminalInfo', {}, (message: unknown) => {
        console.log(message);
      });
    }
  };

  const handleTerminalIdChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTerminalId(e.target.value);
  };

  /**
   * Disconnect from device
   */
  const disconnect = () => {
    const connection = connections.find((connection) => connection.terminalId === terminalId);

    if (connection) {
      connection.disconnect();
    }
  };

  /**
   * Connect to device
   */
  const connect = () => {
    const connection = connections.find((connection) => connection.terminalId === terminalId);

    if (connection) {
      connection.connect();
    }
  };

  /**
   * Initiate device reboot
   */
  const reboot = () => {
    const connection = connections.find((connection) => connection.terminalId === terminalId);

    if (connection) {
      connection.request('Reboot', {});
    }
  };

  const purgeState = () => {
    persistor.purge()
      .then(() => {
        return persistor.flush();
      })
      .then(() => {
        persistor.pause();
      });

    window.location.reload();
  };

  useEffect(() => {
    if (paymentDevice && paymentDevice.logEntries.length > 0) {
      setLogEntries(
        paymentDevice.logEntries.reduce(
          (accumulator: string, currentValue: string) => accumulator + '\n' + currentValue)
      );
    }
  }, [paymentDevice]);

  const handleCashRegistryIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCashRegistryId(e.target.value));
  };

  const handlePaymentTerminalSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const deviceIndex = e.target.name;
    const checked = e.target.checked;

    if (checked) {
      dispatch(setUsedTerminalConnection(deviceIndex));
    } else {
      dispatch(removeUsedTerminalConnection(deviceIndex));
    }
  };

  return (
    <Dialog
      id="customer-dialog"
      onClose={ closeDialog }
      aria-labelledby="dialog-title"
      open={ terminalStore.dialogOpen }
      maxWidth="md"
      fullWidth={ true }
    >
      <DialogTitle>
        Asetukset
      </DialogTitle>
      <DialogContent style={ { paddingBottom: '1rem' } }>
        <Typography variant="h3" style={ { margin: '0 0 1.5rem' } }>Sovellus</Typography>
        <TextField onChange={ handleCashRegistryIdChange } variant="outlined" label="Kassalaitteen tunnus" defaultValue={ appStore.cashRegistryId } fullWidth={ true } helperText={ 'Muutokset tallentuvat automaattisesti' } />
        <Button variant="contained" className="btn-link btn" onClick={ purgeState } style={ {
          marginLeft: '0.25rem',
          marginTop: '1rem'
        } }>Tyhjennä välimuisti</Button>
        <Typography variant="h3" style={ { margin: '1.5rem 0' } }>Maksupäätteen asetukset</Typography>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 6 }>
            <FormControl component="fieldset">
              <FormLabel component="legend">Valitse käytettävät maksupäätteet</FormLabel>
              <FormGroup>
                { connections
                  .filter((connection) => connection.connection !== null && connection.connection.lastPolledStatus === 1)
                  .map((c: Connection, index: number) => (
                    <FormControlLabel
                      key={ c.terminalId }
                      control={
                        <Checkbox checked={ appStore?.usedTerminalConnections.includes(c.terminalId) } onChange={ handlePaymentTerminalSelection } name={ c.terminalId } /> }
                      label={ c.name }
                    />
                  )) }
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={ 6 }>
          </Grid>
          <Typography variant="h3" style={ { margin: '1.5rem 0' } }>Yhteystiedot</Typography>
          <Grid item xs={ 12 }>
            <FormControl variant="outlined" fullWidth={ true }>
              <InputLabel>Valitse maksupääte</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Valitse maksupääte"
                value={ terminalId }
                onChange={ handleTerminalIdChange }
              >
                { connections.map((c: Connection, index: number) => (
                  <MenuItem key={ 'connection-select-options-' + index } value={ c.terminalId }>{ `${ c.name }` }</MenuItem>
                )) }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={ 12 }>
            <TextField multiline variant="outlined" id="websocket-terminal-log" label="Logi" rows={ 8 } fullWidth={ true } value={ logEntries } />
          </Grid>
          <Grid item xs={ 12 } className="flex-child-separation">
            <Chip color="primary"
                  icon={ <AttachMoney /> }
                  label="Valmis siirtoon"
                  className={ paymentDevice && paymentDevice.status.ready_for_transaction ? 'chip-success' : 'chip-danger' } />
            <Chip color="primary"
                  icon={ <SettingsInputAntenna /> }
                  label="Yhteys maksunvälittäjään"
                  className={ paymentDevice && paymentDevice.status.psp_connection_available ? 'chip-success' : 'chip-danger' } />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={ 1 }>
          <Grid item xs={ 10 }>
            <Button variant="contained" color={ 'primary' } className="btn-primary btn" onClick={ connect } style={ {
              marginLeft: '0.25rem',
              marginTop: '0.25rem'
            } }>Muodosta yhteys</Button>
            <Button variant="contained" className="btn-danger btn" onClick={ disconnect } style={ {
              marginLeft: '0.25rem',
              marginTop: '0.25rem'
            } }>Katkaise yhteys</Button>
            <Button variant="contained" className="btn-danger btn" onClick={ reboot } style={ {
              marginLeft: '0.25rem',
              marginTop: '0.25rem'
            } }>Käynnistä uudelleen</Button>
            <Button variant="contained" className="btn-secondary btn" onClick={ showStatus } style={ {
              marginLeft: '0.25rem',
              marginTop: '0.25rem'
            } }>Päivitä tila</Button>
          </Grid>
          <Grid item xs={ 2 } style={ { textAlign: 'right' } }>
            <Button variant="text" onClick={ closeDialog } style={ { marginRight: '1rem' } }>
              Sulje
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditMachineSettings;