import React, { ReactElement, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormGroup,
  TextField,
  Chip, FormHelperText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, toggleDiscountDialog } from '../store/app/appSlice';
import { RootState } from '../store/store';
import { addDiscountPercentage } from '../store/app/purchasesSlice';

const AddDiscount: React.FC = (): ReactElement => {
  const [percentage, setPercentage] = useState<number>(null);

  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: RootState) => state.app);

  useEffect(() => {
    return () => setPercentage(null);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '' && !isNaN(e.target.value as any) && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
      setPercentage(parseInt(e.target.value));
    } else {
      setPercentage(null);
    }
  };

  const handleAddDiscount = () => {
    if (percentage >= 0 && percentage <= 100) {
      dispatch(addDiscountPercentage(percentage));
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(toggleDiscountDialog(false));
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={ appState.discountDialogOpen } maxWidth="xs" fullWidth>
      <DialogTitle>Lisää alennus</DialogTitle>
      <DialogContent style={ { paddingBottom: '1rem' } }>
        <FormGroup style={{ marginBottom: '1rem' }}>
          <FormHelperText>Alennusprosentti lisätään valittuun ostotapahtumaan. Mikäli ostotapahtumalla on jo alennus, uuden syöttäminen ylikirjoittaa sen.</FormHelperText>
        </FormGroup>
        <FormGroup>
          <TextField
            type="number"
            onChange={ handleInputChange }
            label="Alennusprosentti"
            helperText={ percentage === null ? 'Syötä alennusprosentti 0-100' : '' }
            error={ percentage === null }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={ handleClose } style={ { marginRight: '1rem' } }>
          Peruuta
        </Button>
        <Button variant="contained" color="primary" onClick={ handleAddDiscount }>
          Lisää alennus
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDiscount;