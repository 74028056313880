module.exports = {
  devices: [
    {
      name: "Utopia Club A418",
      username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
      password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
      terminalId: process.env.NEXT_PUBLIC_WEBSOCKETPOS_TERMINALID,
      apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
    },
    {
      name: "Utopia Club 202",
      username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
      password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
      terminalId: '39859',
      apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
    },
    {
      name: "Utopia Club 55",
      username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
      password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
      terminalId: '39861',
      apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
    },
    {
      name: "Utopia Club 7fe3",
      username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
      password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
      terminalId: '39858',
      apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
    },
    {
      name: "Utopia Club A173",
      username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
      password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
      terminalId: '39860',
      apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
    },
    {
      name: "Utopia Club H026",
      username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
      password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
      terminalId: '39869',
      apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
    },
    {
      name: "Utopia Club H505",
      username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
      password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
      terminalId: '39868',
      apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
    },
{
  name: "Utopia Club 002",
  username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
  password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
  terminalId: '41129',
  apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
},
  {
  name: "Utopia Club 192",
  username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
  password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
  terminalId: '41128',
  apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
},
{
  name: "Utopia Club FF97",
  username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
  password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
  terminalId: '41132',
  apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
},
{
  name: "Utopia Club A452",
  username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
  password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
  terminalId: '41130',
  apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
},
{
  name: "Utopia Club D265",
  username: process.env.NEXT_PUBLIC_WEBSOCKETPOS_USERNAME,
  password: process.env.NEXT_PUBLIC_WEBSOCKETPOS_PASSWORD,
  terminalId: '41131',
  apiKey: process.env.NEXT_PUBLIC_WEBSOCKETPOS_APIKEY
}
  ]
};
