import { useMemo } from 'react';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import Connection from '../assets/js/lib/Connection';
import deviceConfig from '../../ptconfig';
import { addDevice, reset, setLogEntry, TerminalStatus, updateStatus } from '../store/app/terminalSlice';
import { removeUsedTerminalConnection, setUsedTerminalConnection } from '../store/app/appSlice';
import { cancelAllPendingPurchasesOnTerminalId, moveLoadingToPending } from '../store/app/purchasesSlice'
import { RootState } from '../store/store'

interface ConnectionArg {
  name: string;
  username: string;
  password: string;
  terminalId: string;
  apiKey: string;
}

const buildConnections = (dispatch: Dispatch) => {
  const connections: Connection[] = [];

  deviceConfig.devices.forEach((connectionArgs: ConnectionArg, index: number) => {
    const connection: Connection = new Connection({
      name: connectionArgs.name,
      username: connectionArgs.username,
      password: connectionArgs.password,
      terminalId: connectionArgs.terminalId,
      currency: 'EUR',
      apiKey: connectionArgs.apiKey,
      stateChanged: (state: number, message: string) => {
        dispatch(setLogEntry({ entry: message, terminalId: connectionArgs.terminalId }));

        if (state === 1) {
          toast.success('Yhteys maksupäätteeseen (' + (index + 1) + ') on muodostettu', {
            containerId: 'websocket_connection_successful',
            autoClose: 5000
          });

          dispatch(setUsedTerminalConnection(connectionArgs.terminalId));

          connection.request('Status', {}, (message: TerminalStatus) => dispatch(updateStatus({
            status: message,
            terminalId: connection.terminalId
          })), function () { return; });
        }

        if (state === 3) {
          toast.warn('Yhteys maksupäätteeseen (' + (index + 1) + ') on katkennut');

          dispatch(cancelAllPendingPurchasesOnTerminalId(connectionArgs.terminalId));
          dispatch(removeUsedTerminalConnection(connectionArgs.terminalId));
          dispatch(reset());
        }
      },
      webSocketPosMethods: {
        StatusEvent: function (message: { params: TerminalStatus }, success: (result: unknown) => void,) {
          console.log('StatusEvent:', message);

          if (message.params?.transaction_status === 'WAIT_CARD_IN') {
            dispatch(moveLoadingToPending())
          }

          dispatch(updateStatus({ status: message.params, terminalId: connectionArgs.terminalId }));
          success({});
        },
        CardInfo: function (message: unknown, success: (result: unknown) => void) {
          success({ action: 'off' });
        },
        AppInfo: function (message: unknown, success: (result: unknown) => void) {
          success({ action: 'off' });
        }
      },
    });

    if (connection) {
      dispatch(addDevice(connection.terminalId));
      connections.push(connection);
    }
  });


  return connections;
};

/**
 * Connect to payment devices defined in the configuration file and return them from the hook
 */
export function useConnectToPaymentDevice(): Array<Connection> {
  const dispatch = useDispatch();
  return useMemo(() => buildConnections(dispatch), []);
}