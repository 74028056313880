import { Box } from '@material-ui/core';

interface TabPanelProps {
  children: React.ReactElement;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="product-category-tab-panel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: '1rem' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;